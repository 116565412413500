// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-a-day-at-cis-jsx": () => import("./../../../src/pages/a-day-at-cis.jsx" /* webpackChunkName: "component---src-pages-a-day-at-cis-jsx" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-academics-jsx": () => import("./../../../src/pages/academics.jsx" /* webpackChunkName: "component---src-pages-academics-jsx" */),
  "component---src-pages-affiliationletter-jsx": () => import("./../../../src/pages/affiliationletter.jsx" /* webpackChunkName: "component---src-pages-affiliationletter-jsx" */),
  "component---src-pages-affiliations-jsx": () => import("./../../../src/pages/affiliations.jsx" /* webpackChunkName: "component---src-pages-affiliations-jsx" */),
  "component---src-pages-alumni-speak-jsx": () => import("./../../../src/pages/alumni-speak.jsx" /* webpackChunkName: "component---src-pages-alumni-speak-jsx" */),
  "component---src-pages-apply-online-jsx": () => import("./../../../src/pages/apply-online.jsx" /* webpackChunkName: "component---src-pages-apply-online-jsx" */),
  "component---src-pages-calendar-jsx": () => import("./../../../src/pages/calendar.jsx" /* webpackChunkName: "component---src-pages-calendar-jsx" */),
  "component---src-pages-cambridge-teachers-jsx": () => import("./../../../src/pages/cambridge-teachers.jsx" /* webpackChunkName: "component---src-pages-cambridge-teachers-jsx" */),
  "component---src-pages-careers-jsx": () => import("./../../../src/pages/careers.jsx" /* webpackChunkName: "component---src-pages-careers-jsx" */),
  "component---src-pages-chairmans-message-jsx": () => import("./../../../src/pages/chairmans-message.jsx" /* webpackChunkName: "component---src-pages-chairmans-message-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-documents-1-jsx": () => import("./../../../src/pages/documents1.jsx" /* webpackChunkName: "component---src-pages-documents-1-jsx" */),
  "component---src-pages-early-years-learning-jsx": () => import("./../../../src/pages/early-years-learning.jsx" /* webpackChunkName: "component---src-pages-early-years-learning-jsx" */),
  "component---src-pages-general-policy-and-admission-procedure-jsx": () => import("./../../../src/pages/general-policy-and-admission-procedure.jsx" /* webpackChunkName: "component---src-pages-general-policy-and-admission-procedure-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-infrastructure-jsx": () => import("./../../../src/pages/infrastructure.jsx" /* webpackChunkName: "component---src-pages-infrastructure-jsx" */),
  "component---src-pages-organisation-jsx": () => import("./../../../src/pages/organisation.jsx" /* webpackChunkName: "component---src-pages-organisation-jsx" */),
  "component---src-pages-parent-feedback-jsx": () => import("./../../../src/pages/parent-feedback.jsx" /* webpackChunkName: "component---src-pages-parent-feedback-jsx" */),
  "component---src-pages-pay-fees-online-jsx": () => import("./../../../src/pages/pay-fees-online.jsx" /* webpackChunkName: "component---src-pages-pay-fees-online-jsx" */),
  "component---src-pages-principals-message-jsx": () => import("./../../../src/pages/principals-message.jsx" /* webpackChunkName: "component---src-pages-principals-message-jsx" */),
  "component---src-pages-schooldocuments-jsx": () => import("./../../../src/pages/schooldocuments.jsx" /* webpackChunkName: "component---src-pages-schooldocuments-jsx" */),
  "component---src-pages-schools-for-good-jsx": () => import("./../../../src/pages/schools-for-good.jsx" /* webpackChunkName: "component---src-pages-schools-for-good-jsx" */),
  "component---src-pages-stories-jsx": () => import("./../../../src/pages/stories.jsx" /* webpackChunkName: "component---src-pages-stories-jsx" */),
  "component---src-pages-summer-camp-jsx": () => import("./../../../src/pages/summer-camp.jsx" /* webpackChunkName: "component---src-pages-summer-camp-jsx" */),
  "component---src-pages-values-jsx": () => import("./../../../src/pages/values.jsx" /* webpackChunkName: "component---src-pages-values-jsx" */),
  "component---src-pages-why-us-jsx": () => import("./../../../src/pages/why-us.jsx" /* webpackChunkName: "component---src-pages-why-us-jsx" */),
  "component---src-pages-yearbook-jsx": () => import("./../../../src/pages/yearbook.jsx" /* webpackChunkName: "component---src-pages-yearbook-jsx" */)
}

